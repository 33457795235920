import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ServiceFilter1 from "../element/service-filter1";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
// const casebg = require('./../../assets/images/background/image-14.jpg');

class Services1 extends Component {
  render() {
    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>What We Can Do</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li class="active">Services</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}
        {/* <!-- About Section --> */}
        <section class="about-section">
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="image-wrapper">
                  <div class="image-one">
                    <img
                      src={require("../../assets/images/resource/image-2.jpg")}
                      alt=""
                    />
                  </div>
                  <div
                    class="image-two wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div class="caption">F.</div>
                    <div class="image-outer">
                      <img
                        src={require("../../assets/images/resource/image-3.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="content-box">
                  <div class="sec-title">
                    <div class="sub-title">About Us</div>
                    <h2>
                      We Work With <br />
                      You To Address
                    </h2>
                    <div class="text">
                      Our factory are equipped with cutting edge technology and
                      is able to produces mono and multi layer plastic packaging
                      up to eight colours. We are able to produce bags with
                      different shapes, Textures and thickness such as garment
                      bags, bottom seal bags, side-seal bags, patch handle bags,
                      document flyers, die cut handle bags, diaper bags, tissue
                      bags and others.
                      <br /> We are one of GMI ( Graphics Measures International
                      ) certified packaging supplier in the word that is
                      entrusted to produce Target Brand Packaging. We ensure
                      that the same standard and Control measurement is fully
                      implemented in the production of all of our products. We
                      are equipped with equipments such as X-Rite Eye-One /
                      spectrodensitometer to ensure colour control and
                      consistency in our printed product
                    </div>
                  </div>
                  <div class="row">
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-1.png")}
                            alt=""
                          />
                        </div>
                        <h5>Phone Number</h5>
                        <h2>+62 21 555 1370</h2>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-2.png")}
                            alt=""
                          />
                        </div>
                        <h5>Email Address</h5>
                        <h2>sales@aneka-warna.com</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Services Section Three --> */}
        <section class="services-section-three style-two">
          <div class="auto-container">
            <div class="row">
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-4">
                <div class="inner-box">
                  <div class="icon">
                    <span class="flaticon-team"></span>
                    <span class="round-shape"></span>
                  </div>
                  <h3>Business Management</h3>
                  <div class="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-4">
                <div class="inner-box">
                  <div class="icon">
                    <span class="flaticon-money"></span>
                    <span class="round-shape"></span>
                  </div>
                  <h3>Criminal Case</h3>
                  <div class="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-4">
                <div class="inner-box">
                  <div class="icon">
                    <span class="flaticon-assets"></span>
                    <span class="round-shape"></span>
                  </div>
                  <h3>Property Planning</h3>
                  <div class="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-4">
                <div class="inner-box">
                  <div class="icon">
                    <span class="flaticon-world"></span>
                    <span class="round-shape"></span>
                  </div>
                  <h3>Transport & Export</h3>
                  <div class="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-4">
                <div class="inner-box">
                  <div class="icon">
                    <span class="flaticon-money-1"></span>
                    <span class="round-shape"></span>
                  </div>
                  <h3>Saving Tax</h3>
                  <div class="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-4">
                <div class="inner-box">
                  <div class="icon">
                    <span class="flaticon-notebook"></span>
                    <span class="round-shape"></span>
                  </div>
                  <h3>Growth Business</h3>
                  <div class="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Case Studies --> */}
        {/* <ServiceFilter1/> */}

        <Footer />
      </>
    );
  }
}
export default Services1;
