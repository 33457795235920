import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import ReactGA from "react-ga";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HomeSlider1 from "./../element/home-slider1";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

// import '../../assets/css/color.css';

import VideoPopup1 from "./../element/video-popup1";
import PortfolioFilter1 from "./../element/portfolio-filter1";

const teambg1 = require("./../../assets/images/background/image-3.jpg");
const newsbg1 = require("./../../assets/images/background/image-4.jpg");
const wellcomebg1 = require("./../../assets/images/resource/image-1.jpg");

class Index extends Component {
  render() {
    return (
      <>
        <Header />
        {ReactGA.pageview(window.location.pathname + window.location.search)}
        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form method="post" action="http://aneka-warna.com">
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <HomeSlider1 />
        {/* <!-- Welcome Setion --> */}
        <section class="welcome-section pt-0">
          <div class="auto-container">
            <div class="wrapper-box">
              <div class="row m-0">
                {/* <!-- Welcome Block One --> */}
                <div
                  class="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="inner-box">
                    <div class="icon-box">
                      <span class="flaticon-analysis"></span>
                    </div>
                    <h2>Product Analysis</h2>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore.{" "}
                    </div>
                    <div class="read-more">
                      <Link to={"/#"}>Read More</Link>
                    </div>
                  </div>
                </div>
                {/* <!-- Welcome Block One --> */}
                <div
                  class="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div
                    class="inner-box"
                    style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}
                  >
                    <div class="icon-box">
                      <span class="flaticon-tax"></span>
                    </div>
                    <h2>Packaging Insight</h2>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore.{" "}
                    </div>
                    <div class="read-more">
                      <Link to={"/#"}>Read More</Link>
                    </div>
                  </div>
                </div>
                {/* <!-- Welcome Block One --> */}
                <div
                  class="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="inner-box">
                    <div class="icon-box">
                      <span class="flaticon-work-1"></span>
                    </div>
                    <h2>Mass Production</h2>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore.{" "}
                    </div>
                    <div class="read-more">
                      <Link to={"/#"}>Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Services Section --> */}
        <section class="services-section">
          <div class="auto-container">
            <div class="wrapper-box">
              <div class="left-column">
                <div class="services-content">
                  <div class="sec-title light">
                    <div class="sub-title">Our Services</div>
                    <h2>
                      What Actually We <br />
                      Do Here.
                    </h2>
                  </div>
                  <div
                    class="icon-box wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div class="icon">
                      <img
                        src={require("../../assets/images/icons/icon-3.png")}
                        alt=""
                      />
                    </div>
                    <h2>Capital Market</h2>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </div>
                  </div>
                  <div
                    class="icon-box wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div class="icon">
                      <img
                        src={require("../../assets/images/icons/icon-4.png")}
                        alt=""
                      />
                    </div>
                    <h2>Insurance & Planning</h2>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </div>
                  </div>
                  <div
                    class="icon-box wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div class="icon">
                      <img
                        src={require("../../assets/images/icons/icon-5.png")}
                        alt=""
                      />
                    </div>
                    <h2>Financial Strategy</h2>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-column">
                {/* <!-- Fact Counter --> */}
                <div class="fact-counter">
                  <div class="border-box">
                    <div class="border_top"></div>
                    <div class="border_bottom"></div>
                    <div class="border_middile"></div>
                  </div>
                  <div class="row">
                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-6">
                      <div
                        class="inner wow fadeInLeft"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="content">
                          <div class="icon">
                            <img
                              src={require("../../assets/images/icons/icon-6.png")}
                              alt=""
                            />
                          </div>
                          <div class="count-outer count-box">
                            <CountUp end={90} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span class="count-text" ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                            <span>%</span>
                          </div>
                          <div class="counter-title">Business Monitoring</div>
                        </div>
                      </div>
                    </div>

                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-6">
                      <div
                        class="inner wow fadeInLeft"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="content">
                          <div class="icon">
                            <img
                              src={require("../../assets/images/icons/icon-7.png")}
                              alt=""
                            />
                          </div>
                          <div class="count-outer count-box">
                            <CountUp end={71} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span class="count-text" ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                            <span>%</span>
                          </div>
                          <div class="counter-title">Business Growth</div>
                        </div>
                      </div>
                    </div>

                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-6">
                      <div
                        class="inner wow fadeInLeft"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="content">
                          <div class="icon">
                            <img
                              src={require("../../assets/images/icons/icon-8.png")}
                              alt=""
                            />
                          </div>
                          <div class="count-outer count-box">
                            <CountUp end={33} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span class="count-text" ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                            <span>%</span>
                          </div>
                          <div class="counter-title">Financial Plan</div>
                        </div>
                      </div>
                    </div>

                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-6">
                      <div
                        class="inner wow fadeInLeft"
                        data-wow-delay="900ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="content">
                          <div class="icon">
                            <img
                              src={require("../../assets/images/icons/icon-9.png")}
                              alt=""
                            />
                          </div>
                          <div class="count-outer count-box">
                            <CountUp end={42} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span class="count-text" ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                            <span>%</span>
                          </div>
                          <div class="counter-title">Finance Consulting</div>
                        </div>
                      </div>
                    </div>

                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-6">
                      <div
                        class="inner wow fadeInLeft"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="content">
                          <div class="icon">
                            <img
                              src={require("../../assets/images/icons/icon-10.png")}
                              alt=""
                            />
                          </div>
                          <div class="count-outer count-box">
                            <CountUp end={69} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span class="count-text" ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                            <span>%</span>
                          </div>
                          <div class="counter-title">Data Analysis</div>
                        </div>
                      </div>
                    </div>

                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-6">
                      <div
                        class="inner wow fadeInLeft"
                        data-wow-delay="900ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="content">
                          <div class="icon">
                            <img
                              src={require("../../assets/images/icons/icon-11.png")}
                              alt=""
                            />
                          </div>
                          <div class="count-outer count-box">
                            <CountUp end={10} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span class="count-text" ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                            <span>%</span>
                          </div>
                          <div class="counter-title">business Strategy</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="author-box"
                  style={{
                    backgroundImage:
                      "url(assets/images/background/image-1.jpg)",
                  }}
                >
                  <div class="author-info">
                    <div class="author-thumb">
                      <img
                        src={require("../../assets/images/resource/author-1.jpg")}
                        alt=""
                      />
                    </div>
                    <h2>Rosalina D. William</h2>
                    <div class="designation">Founder</div>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod..
                    </div>
                  </div>
                  <div class="signature">
                    <img
                      src={require("../../assets/images/resource/signature.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Video Section -->
        <VideoPopup1 /> */}
        {/* <!-- Why Choose Us Section --> */}
        <section class="why-chooseus-section">
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="content-box">
                  <div class="sec-title">
                    <div class="sub-title">Why Choose Us</div>
                    <h2>
                      Why Should You <br />
                      Choose Us ?
                    </h2>
                  </div>
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav nav-tabs tab-btn-style-one"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <span>Our Mission</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <span>Our Vision</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <span>Our Value</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="tabs-content">
                      <Tab.Pane eventKey="first" className="fadeInUp animated">
                        <div class="clearfix">
                          <div class="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <p>
                              Our mission is to provide the best quality,
                              service and price to our customer.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="fadeInUp animated">
                        <div class="clearfix">
                          <div class="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <p>
                              We will be an innovator and leader in the
                              rotogravure flexible packaging industry locally
                              and globally.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third" className="fadeInUp animated">
                        <div class="clearfix">
                          <div class="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <p>
                              In moving forward with the business and expanding
                              it in terms of Plastic Industry for Rotogravure
                              Printing and flexible packaging, we are committed
                              as a company that put our customer first in mind.
                            </p>
                            <p>
                              We achieve it by following our targets:
                              <ul>
                                <li>
                                  1. Achieving products of the utmost quality.
                                </li>
                                <li>
                                  2. Giving services and best solutions in
                                  regard to quality.
                                </li>
                                <li>3. On-time delivery</li>
                                <li>
                                  4. Working and partnering with our customers,
                                  suppliers and staff, shareholders to achieve
                                  this goals.
                                </li>
                                <li>
                                  5. Reviewing the system and process that have
                                  to do with quality and achieve continuous
                                  improvement.
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="image-wrapper">
                  <div class="image-one">
                    <img
                      src={require("../../assets/images/resource/image-6.jpg")}
                      alt=""
                    />
                  </div>
                  <div class="image-two">
                    <img
                      src={require("../../assets/images/resource/image-5.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Team Section --> */}
        <section
          class="team-section"
          style={{ backgroundImage: "url(" + teambg1 + ")" }}
        >
          <div class="auto-container">
            <div class="sec-title text-center">
              <div class="sub-title">Our Achievement</div>
              <h2>Our Certificates</h2>
            </div>
            <div class="row">
              {/* <!-- Team Block One --> */}
              <div
                class="col-lg-4 team-block-one wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="image">
                    <img src={require("../../assets/images/acm.jpg")} alt="" />
                  </div>
                  <div class="content">
                    <div class="designation">Founder</div>
                    <h3>Rosalina D. William</h3>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore.
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Team Block One --> */}
              <div
                class="col-lg-4 team-block-one wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="image">
                    <img
                      src={require("../../assets/images/resource/team-2.jpg")}
                      alt=""
                    />
                  </div>
                  <div class="content">
                    <div class="designation">CEO</div>
                    <h3>Velavos H. DesuJa</h3>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore.
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Team Block One --> */}
              <div
                class="col-lg-4 team-block-one wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="image">
                    <img
                      src={require("../../assets/images/resource/team-1.jpg")}
                      alt=""
                    />
                  </div>
                  <div class="content">
                    <div class="designation">Developer</div>
                    <h3>Abdur Rahman J.</h3>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Contact Section --> */}
        <section class="contact-section">
          <div class="auto-container">
            <div class="row">
              <div class="col-lg-6">
                <div class="sec-title">
                  <div class="sub-title">Write Here</div>
                  <h2>Get In Touch</h2>
                </div>
                {/* <!-- Contact Form--> */}
                <div class="contact-form">
                  <form
                    method="post"
                    action="http://azim.commonsupport.com/Finandox/sendemail.php"
                    id="contact-form"
                  >
                    <div class="row clearfix">
                      <div class="col-md-12 form-group">
                        <label for="name">Enter your name</label>
                        <input
                          type="text"
                          name="username"
                          id="name"
                          placeholder="Enter name here......"
                          required=""
                        />
                        <i class="fas fa-user"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <label for="email">Enter your email</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter email here......"
                          required=""
                        />
                        <i class="fas fa-envelope"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <label for="message">Enter your message</label>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter message here......"
                        ></textarea>
                        <i class="fas fa-edit"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <button
                          class="theme-btn btn-style-one"
                          type="submit"
                          name="submit-form"
                        >
                          <span class="btn-title">Get In Touch</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="sec-title">
                  <div class="sub-title">Get Us Here</div>
                  <h2>Contact Us</h2>
                </div>
                <div class="contact-info">
                  <div class="border-box">
                    <div class="border_top"></div>
                    <div class="border_middile"></div>
                  </div>
                  <div class="row">
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-email-6"></span>
                        </div>
                        <h3>Email Address</h3>
                        <ul>
                          <li>
                            <Link to={"/mailto:sales@aneka-warna.com"}>
                              sales@aneka-warna.com
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-call-1"></span>
                        </div>
                        <h3>Phone Number</h3>
                        <ul>
                          <li>
                            <Link to={"/tel:+8976765654654"}>
                              +897 676 5654 654
                            </Link>
                          </li>
                          <li>
                            <Link to={"/tel:+908(097)56476576"}>
                              +908(097) 564 765 76
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-location"></span>
                        </div>
                        <h3>Office Address</h3>
                        <ul>
                          <li>
                            12/A, Romania City Town Hall <br />
                            New Joursey, UK
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-worldwide"></span>
                        </div>
                        <h3>Web Connect</h3>
                        <ul>
                          <li>
                            <Link to={"/http://example.com/"}>example.com</Link>
                          </li>
                          <li>
                            <Link to={"/http://jobs.example.com/"}>
                              jobs.example.com
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- News Section --> */}
        <section class="news-section">
          <div
            class="sec-bg"
            style={{ backgroundImage: "url(" + newsbg1 + ")" }}
          ></div>
          <div class="auto-container">
            <div class="sec-title text-center light">
              <div class="sub-title">News</div>
              <h2>News From Resource</h2>
            </div>
            <div class="row">
              {/* <!-- News Block One --> */}
              <div
                class="news-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="image">
                    <Link to={"/#"}>
                      <img
                        src={require("../../assets/images/resource/news-1.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div class="lower-content">
                    <div class="category">Business</div>
                    <ul class="post-meta">
                      <li>
                        <Link to={"/#"}>
                          <i class="far fa-calendar-alt"></i>24th March 2020
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <i class="far fa-user"></i>By Admin
                        </Link>
                      </li>
                    </ul>
                    <h3>
                      <Link to={"/blog-details"}>
                        UX is best solution for your <br />
                        business & make noise.
                      </Link>
                    </h3>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                      sed do eiusmod temp or incididunt ut labore et dolore
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- News Block One --> */}
              <div
                class="news-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="image">
                    <Link to={"/#"}>
                      <img
                        src={require("../../assets/images/resource/news-2.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div class="lower-content">
                    <div class="category">Business</div>
                    <ul class="post-meta">
                      <li>
                        <Link to={"/#"}>
                          <i class="far fa-calendar-alt"></i>26th March 2020
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <i class="far fa-user"></i>By Admin
                        </Link>
                      </li>
                    </ul>
                    <h3>
                      <Link to={"/blog-details"}>
                        Business consultant is involved in the planning.
                      </Link>
                    </h3>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                      sed do eiusmod temp or incididunt ut labore et dolore
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- News Block One --> */}
              <div
                class="news-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="image">
                    <Link to={"/#"}>
                      <img
                        src={require("../../assets/images/resource/news-3.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div class="lower-content">
                    <div class="category">Business</div>
                    <ul class="post-meta">
                      <li>
                        <Link to={"/#"}>
                          <i class="far fa-calendar-alt"></i>28th March 2020
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <i class="far fa-user"></i>By Admin
                        </Link>
                      </li>
                    </ul>
                    <h3>
                      <Link to={"/blog-details"}>
                        Business consultant is a professional who analyzes.
                      </Link>
                    </h3>
                    <div class="text">
                      Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                      sed do eiusmod temp or incididunt ut labore et dolore
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Index;
